<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title
          >Detail Anggota
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="$router.go(-1)">Kembali</v-btn>
        </v-card-title>
        <v-alert small dense type="info">
          {{ item.status_aktif_anggota }}
        </v-alert>
        <v-simple-table dense style="width: 100%">
          <tbody>
            <tr>
              <td><b>Kode Anggota</b> : {{ item.kode_anggota }}</td>
              <td><b>Nama</b> : {{ item.nama }}</td>
              <td>
                <b>Wilayah</b> :
                <span v-if="item.wilayah_luar === '1'" style="color: red">{{ item.wilayah }}</span>
                <span v-else>{{ item.wilayah }}</span>
              </td>
            </tr>
            <tr>
              <td><b>Tgl Lahir</b> : {{ item.tgl_lahir }}</td>
              <td><b>NIK</b> : {{ item.nik }}</td>
              <td><b>Jenis Upah</b> : {{ item.jenis_upah }}</td>
            </tr>
            <tr>
              <td><b>TGL Masuk</b> : {{ item.tgl_masuk }}</td>
              <td><b>Jabatan</b> : {{ item.jabatan }}</td>
              <td><b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(item.jumlah_upah) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table class="mt-4" dense style="width: 100%">
          <tbody>
            <tr>
              <td><b>Total Simpanan</b></td>
              <td><b>:</b></td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(saldo.totalsimpanan) }}</td>
            </tr>
            <tr>
              <td><b>Total Tabungan</b></td>
              <td><b>:</b></td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(saldo.totaltabungan) }}</td>
            </tr>
            <tr>
              <td><b>Total Pokok Pinjaman</b></td>
              <td><b>:</b></td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(saldo.kurangPokokPinjaman) }}</td>
            </tr>
            <tr>
              <td><b>Bunga Pinjaman</b></td>
              <td><b>:</b></td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(saldo.bungapinjamperAngsur) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-dialog v-model="dialog" persistent max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mx-4 my-4"
              dark
              v-bind="attrs"
              v-on="on"
              v-if="anggota.status_aktif_anggota == 'AKTIF'"
            >
              Lunasi Pinjaman
            </v-btn>
          </template>
          <v-card>
            <v-card-title> Lunasi Pinjaman </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Total Pinjaman Harus Dibayar"
                      type="number"
                      v-model="editedItem.pinjaman"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                    <div class="ml-3" style="color: red">
                      {{ new Intl.NumberFormat(['id']).format(editedItem.pinjaman) }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false"> Batal </v-btn>
              <v-btn color="primary" @click="lunasi"> Lunasi </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogKeluar" persistent max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="danger"
              class="mx-4 my-4"
              dark
              v-bind="attrs"
              v-on="on"
              v-if="item.status_aktif_anggota == 'AKTIF'"
            >
              NON AKTIFKAN ANGGOTA
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">Apakah yakin akan me-non aktifkan anggota ini?</v-card-title>
            <v-card-text> Pastikan simpanan, tabungan dan pinjaman anggota sudah di selesaikan </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialogKeluar = false"> Batal </v-btn>
              <v-btn color="primary" @click="non_aktifkan">Ya, Saya Yakin </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      id_anggota: this.$route.params.id_anggota,
      dialogKeluar: false,
      dialog: false,
      editedItem: {
        pinjaman: { text: '', value: '' },
        besar_simpanan: 0,
        tgl_simpanan: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
      item: {
        status_aktif_anggota: 'AKTIF',
      },
      saldo: {},
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    anggota() {
      return this.item
    },
  },
  methods: {
    initialize() {
      this.getAnggota()
      this.getSaldoAnggota()
    },
    getAnggota() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Anggota/get/${this.id_anggota}`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.item = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getSaldoAnggota() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const fmData = new FormData()
      fmData.append('id_anggota', this.id_anggota)
      axios
        .post(`${apiRoot}/api/Anggota/getSemuabyIdAnggota/${this.id_anggota}`, fmData, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.saldo = Object.assign({}, response.data.data)
            this.editedItem.pinjaman =
              parseFloat(this.saldo.kurangPokokPinjaman) + parseFloat(this.saldo.bungapinjamperAngsur)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    lunasi() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggota', this.id_anggota)
      form.append('besar_angsuran', this.editedItem.pinjaman)
      axios
        .post(`${apiRoot}/api/Anggota/lunasiHutang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getSaldoAnggota()
            this.dialog = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    non_aktifkan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggota', this.id_anggota)
      axios
        .post(`${apiRoot}/api/Anggota/anggotaKeluar`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getAnggota()
            this.getSaldoAnggota()
            this.dialogKeluar = false
            this.$router.go(-1)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
